body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-content {
  min-height: 100vh !important;
}

textarea:disabled {
  background-color: white !important;
  cursor: pointer !important;
  font-weight: bold !important;
  color: grey !important;
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
    height: 340px;
    border-radius: 8px;
    transition: 0.3s;
    opacity: 0.2;
    cursor: pointer;
    p {
      display: none
    }
}

.overlay:hover {
  background-color: #F0F0F0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  opacity: 0.2;

  p {
      display: block
    }
}

.position-relative {
  position: relative;
}


@media screen and (max-width: 850px) {
  textarea {
    min-width: calc(100vw - 108px) !important;
  }
  .textarea-copy {
    bottom: 10px !important;
  }
  .overlay {
    display: none;
  }
}

 
@media (hover: hover) {
  .textarea-copy {
    display: none;
  }
}
